<template lang="pug">
v-container
    v-card(light, flat)
        v-card-subtitle.pl-10.pr-10
            v-text-field(
                v-model='search',
                prepend-inner-icon='search',
                label='Search',
                single-line,
                hide-details,
                clearable, clear-icon='clear',
                color='brand',
                outlined, dense, light, solo, rounded
            )
        v-card-text
            v-data-table(
                :headers='headers'
                :items='customers'
                :search='search'
                disable-pagination
                hide-default-footer
                height='85vh'
                fixed-header
                light, dense, striped
            )
                template(v-slot:[`item.customersVersion.lastGitUpdate`]='{item}')
                    span {{ formattedDate(item.customersVersion.lastGitUpdate) }}
                template(v-slot:item.update='{item}')
                    template(v-if='item.jobStatusUpdate === "none" || item.jobStatusUpdate === "failed" ')
                        v-btn.white--text.updateButton(
                            @click='updateNow(item)',
                            :color="item.jobStatusUpdate === 'failed' ? 'red' : 'green'"
                            x-small
                        ) Publish Update
                    template(v-if='item.jobStatusUpdate === "queued"')
                        v-btn(disabled, x-small) Update Queued
                    template(v-if='item.jobStatusUpdate === "running"')
                        v-progress-circular(indeterminate, color='blue', size='24')
                        span.ml-2 Updating...
                template(v-slot:item.installer='{item}')
                    template(v-if='item.jobStatusInstall === "none" || item.jobStatusInstall === "failed"')
                        v-btn.white--text.updateButton(
                            @click="previewInstaller(item)",
                            :color="item.jobStatusInstall === 'failed' ? 'red' : 'blue'"
                            x-small
                        ) Create Installer
                    template(v-if='item.jobStatusInstall === "queued"')
                        v-btn(disabled, x-small) Queued
                    template(v-if='item.jobStatusInstall === "running"')
                        v-progress-circular(indeterminate, color='blue', size='24')
                        span.ml-2 Running...
                template(v-slot:item.installerFile='{item}')
                    v-tooltip(top, v-if='item.installerFile')
                        template(v-slot:activator="{ on, attrs }")
                            v-btn.white--text.updateButton(
                                v-bind="attrs", v-on="on"
                                @click="downloadFile(item)",
                                color='warning',
                                x-small
                            ) Download
                                v-icon(small) download
                        span created {{ item.installerAge }} ago

        v-overlay(absolute, :value='busy')
            .text-h6 Please Wait...
            v-progress-linear(indeterminate)

    InstallerPreview(
        v-model='showInstallerPreview',
        :customer="installerCustomer",
        :visible="showInstallerPreview",
        :dbUsers="users",
        @close='showInstallerPreview=false')

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script lang="js">
import dti from 'dti';
import InstallerPreview from '@/components/devops/installerPreview';
import moment from 'moment';
import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

export default {
    data () {
        return {
            dti,
            accessKey: 'devopsupdate',
            customers: [],
            newUserErrors: [],
            editUserErrors: [],
            editingUser: null,
            showInstallerPreview: false,
            customerId: 0,
            hasChanges: false,
            confirmDialog: false,
            installerCustomer: {},
            message: '',
            headers: [
                {
                    text: 'Customer',
                    value: 'name'
                },
                {
                    text: 'Last Updated',
                    value: 'customersVersion.lastGitUpdate'
                },
                {
                    text: 'Available Version',
                    value: 'customersVersion.jsCodeGit'
                },
                {
                    text: 'Current Version',
                    value: 'customersVersion.jsCodeServer'
                },
                {
                    text: 'Updater',
                    value: 'update',
                    sortable: false
                },
                {
                    text: 'Installer',
                    value: 'installer',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Installer Download',
                    value: 'installerFile',
                    sortable: false,
                }
            ],
            users: [],
            search: ''
        };
    },
    components: {
        InstallerPreview
    },
    methods: {
        formattedDate (dateString) {
            if (dateString) {
                let date = new Date(dateString);

                return date.toLocaleString('en-US');
            }
        },
        async updateNow (data) {
            this.message = `Customer Update Started for ${data.name}. This can take upwards of 20 minutes.`;
            this.socketEmit('updateSingleClient', {
                customerId: data.id,
                name: data.name
            });
        },

        /**
         * Opens the infoscan installer preview dialog
         *
         * @param {object} customer
         */
        previewInstaller (customer) {
            this.installerCustomer = customer;
            this.showInstallerPreview = true;
        },

        /**
         * @param {object} data
         */
        getCustomers () {
            this.socketEmit('getCustomers', ['versions', 'config', 'keys', 'machines', 'jobqueues', 'installers', 'credentials'], (results) => {
                this.customers = results || [];
                this.customers.sort((a, b) => a.name.localeCompare(b.name));
                this.customers.forEach(customer => {
                    const updateStatus = this.getJobStatus(customer, 'update');
                    const installerStatus = this.getJobStatus(customer, 'installer');
                    this.$set(customer, 'jobStatusUpdate', updateStatus );
                    this.$set(customer, 'jobStatusInstall', installerStatus);
                    if(customer.customersInstallers[0]){
                        this.$set(customer, 'installerFile', customer.customersInstallers[0].location);
                        this.$set(customer, 'installerDate', customer.customersInstallers[0].updatedAt);
                        this.$set(customer, 'installerAge', moment(customer.customersInstallers[0].updatedAt).fromNow(true));
                    }
                });
            });
        },

        /**
         * obtain the database user groups and users
         */
        getUsers () {
            this.socketEmit('getDatabaseUsers', null, (results) => {
                this.users = results || [];
            });
        },

        /**
         * Listener for customer status updates
         *
         * @param {object} data
         * @param {number} data.customerId
         * @param {number} data.jobStatus
         * @param {number} data.jobType - 'update' or 'installer'
         */
        handleCustomerStatusUpdate (data) {
            if(data.hasOwnProperty('customerId') === false) {
                throw new Error('handleCustomerStatusUpdate: data must have customerId property');
            }
            const customer = this.customers.find(c => c.id === data.customerId);
            if(data.jobType === 'update') {
                this.$set(customer, 'jobStatusUpdate', data.jobStatus);
            } else if (data.jobType === 'installer') {
                this.$set(customer, 'jobStatusInstall', data.jobStatus);
            }

        },

        /**
         * Whats going on within the job queue for this customer
         * @param {object} customer
         * @param {string} jobType - 'update' or 'installer'
         * @returns {string} - 'none', 'queued', 'running'
         */
        getJobStatus (customer, jobType = 'update') {
            // need to distinguish between installer and update
            let state = "queued";
            const activeJobs = customer.customersJobQueues.find(job => job.active && job.jobType === jobType);
            if (!activeJobs) {
                state = 'none';
            } else if (activeJobs.isRunning) {
                state = 'running';
            }
            return state;
        },

        handleAlert (message) {
            this.message = message;
        },

        /**
         * Downloads the installer for the customer
         * - have to use fetch since axios doesn't support streams
         * @param {object} customer
         * @param {string} customer.name
         * @param {string} customer.state
         * @param {string} customer.installerFile
         * @param {Date} customer.updatedAt
         */
        downloadFile (customer) {
            const {installerFile, installerDate, name, state} = customer;
            const dt = moment(installerDate).format('YYYY-MM-DD_HH-mm');
            const properName = name.replace(/ /g, '-');
            const fileName = `ServerInstall-for-${properName}-${state}_${dt}.zip`;
            const apiKey = this.$store.getters.apiKey;
            return fetch(`${this.baseUrl}/devopsapi/downloadInstaller`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'DC-Auth': apiKey
                },
                body: JSON.stringify({
                    installerFile,

                })
            }).then(res => {

                if (!window.WritableStream) {
                    streamSaver.WritableStream = WritableStream;
                    window.WritableStream = WritableStream;
                }

                const fileStream = streamSaver.createWriteStream(fileName);
                const writer = fileStream.getWriter();
                if (res.body.pipeTo) {
                    writer.releaseLock();
                    return res.body.pipeTo(fileStream);
                }

                const reader = res.body.getReader();
                const pump = () =>
                    reader
                        .read()
                        .then(({ value, done }) => (done ? writer.close() : writer.write(value).then(pump)));

                return pump();
            });
        },

        /**
         * constructs the base url when using fetch
         */
        baseUrl (){
            return `${window.location.protocol}//${window.location.hostname}:${CONFIG.dev ? CONFIG.devApiPort : window.location.port}`;
        },

        /**
         * applies latest information about the installer to the customer
         * @param {object} data
         * @param {number} data.customerId
         * @param {string} data.location
         * @param {Date=} data.updatedAt
         * @param {Date=} data.deleted
         */
        handleInstallerUpdate (data) {
            const customer = this.customers.find(c => c.id === data.customerId);
            if(data.deleted){
                this.$delete(customer, 'installerFile');
            } else {
                this.$set(customer, 'installerDate', data.updatedAt);
                this.$set(customer, 'installerFile', data.location);
                this.$set(customer, 'installerAge', moment(data.updatedAt).fromNow(true));
            }
        }

    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        }
    },
    mounted () {
        this.baseUrl = this.baseUrl();
        this.getUsers();
        this.getCustomers();
        window.customerupdatevm = this;
        this.socketOn('customerStatus', this.handleCustomerStatusUpdate);
        this.socketOn('showAlert', this.handleAlert);
        this.socketOn('installerUpdate', this.handleInstallerUpdate);
    },
};
</script>

<style lang="scss" scoped>
.editButtons {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}
</style>

<style lang="scss"></style>
